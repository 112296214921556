import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import type { SiteEntitlements } from '../models/service-alp.model';
import { queryKeys, fetchSiteEntitlementsURL } from './urls';

export function useSiteEntitlements({
  accountId,
  siteId,
  enabled,
}: {
  accountId: number | undefined;
  siteId: number | undefined;
  enabled: boolean;
}) {
  const { data, isLoading } = useQuery({
    queryKey: queryKeys.siteEntitlements(accountId, siteId),
    queryFn: async () => {
      if (!accountId || !siteId) {
        return undefined;
      }

      try {
        const response = await fetch(
          fetchSiteEntitlementsURL(accountId, siteId)
        );
        const returnData: SiteEntitlements = await response.json();
        return returnData.entitlements;
      } catch (error: unknown) {
        if (error instanceof Error) {
          throw new Error(
            `Error getting Site ${siteId} Entitlements: ${error.message}.`
          );
        }
      }
    },
    enabled: !isNil(accountId) && enabled,
  });

  return {
    siteEntitlementsData: data,
    isLoading,
  };
}
