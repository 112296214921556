import { Typography } from '@mui/material';
import moment from 'moment';
import { AlpPlan } from '../../models/service-alp.model';
import { PlanBadge } from '../../ui/plan-badge';

export function dateRenderer(date: string) {
  const dateTime = moment.utc(date);
  return (
    <>
      <Typography noWrap>{dateTime.format('YYYY-MM-DD')}</Typography>
      {dateTime.format('HH:mm:ss')}
    </>
  );
}

export function toCapitalizeRenderer(value: string) {
  return <Typography textTransform="uppercase">{value}</Typography>;
}

export function toSmallCapitalized(value: string) {
  return (
    <Typography textTransform="uppercase" fontSize={12}>
      {value}
    </Typography>
  );
}

export function planRenderer(value: AlpPlan) {
  return <PlanBadge plan={value} />;
}
