import { Moment } from 'moment';
import type { ProductCatalogItem } from '../../../typings';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export type ServiceProvisionPayload = {
  planDXA: ProductCatalogItem['sku'] | null;
  planVoC: ProductCatalogItem['sku'] | null;
  serviceStartDate: Moment | null;
  serviceEndDate: Moment | null;
  annotation: string;
  source: string;
};

async function provisionService(
  data: ServiceProvisionPayload,
  accountId: number
) {
  const payload = {
    action: 'provision_account_services',
    adm_action_annotation: data.annotation,
    source: data.source,
    service_period_start_date: data.serviceStartDate?.format('YYYY-MM-DD'),
    service_period_end_date: data.serviceEndDate?.format('YYYY-MM-DD'),
    skus: [data.planDXA, data.planVoC].filter(Boolean),
  };

  try {
    const response = await fetch(`/api/v2/adm/account/${accountId}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to provision service');
    }
    return response.json();
  } catch (error) {
    throw new Error('Failed to provision service');
  }
}

export function useServiceProvisionMutation(
  accountId: number,
  options: UseMutationOptions<Response, Error, ServiceProvisionPayload>
) {
  return useMutation({
    ...options,
    mutationFn: (data: ServiceProvisionPayload) => {
      return provisionService(data, accountId);
    },
  });
}
