import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  FormControl,
  TextField,
} from '@mui/material';
import { useToasts } from 'react-toast-notifications';
import HasPermission from '../../../../components/HasPermission';
import { useClearServiceMutation } from './useClearServiceMutation';
import { LoadingButton } from '@mui/lab';
import { useForm, FormProvider } from 'react-hook-form';
import { queryClient } from '../../../../query-client-provider';
import { queryKeys } from '../../api/urls';
import type { ProductPlanAllowanceChange } from '../../../sites/core/get-scheduled-product-changes';
import { ProductPlanAllowanceBadge } from './product-plan-allowance';
import styles from './ClearServiceModal.module.css';

type ClearServiceFormData = {
  annotation: string;
};

type ProvisionServiceModalProps = {
  handleClose: () => void;
  isOpen: boolean;
  accountId: number;
  changes: ProductPlanAllowanceChange[];
};

function getErrorMessage(error: unknown, defaultMessage: string) {
  if (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return error.message;
  }
  return defaultMessage;
}

export function ClearServiceModal({
  handleClose,
  isOpen,
  accountId,
  changes,
}: ProvisionServiceModalProps) {
  const { addToast } = useToasts();
  const { mutate, isLoading } = useClearServiceMutation(accountId, {
    onSuccess: async () => {
      addToast('Service cleared successfully', { appearance: 'success' });
      await queryClient.invalidateQueries([
        queryKeys.accountServicePeriods(accountId),
        queryKeys.siteEntitlements(accountId, undefined),
      ]);
      handleClose();
    },
    onError: (error: unknown) => {
      const message = getErrorMessage(error, 'Failed to clear service');
      addToast(message, { appearance: 'error' });
    },
  });

  const form = useForm<ClearServiceFormData>({
    defaultValues: {
      annotation: '',
    },
  });
  const {
    formState: { errors },
  } = form;

  function onSubmit(data: ClearServiceFormData) {
    mutate({
      annotation: data.annotation,
    });
  }

  const rolesAccepted = ['admin', 'dxa_voc_manual_provisioning'];

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      maxWidth="md"
    >
      <HasPermission
        rolesAccepted={rolesAccepted}
        message={`You are not authorized to provision services. Required roles: ${rolesAccepted.join(
          ', '
        )}`}
      >
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
            <DialogTitle id="parent-modal-title">
              Clear (fair) downgrade service
            </DialogTitle>
            <DialogContent>
              {changes.map((change) => (
                <p className={styles.changeContainer}>
                  <ProductPlanAllowanceBadge allowance={change.now} />
                  <span>→</span>
                  <ProductPlanAllowanceBadge allowance={change.later} />
                </p>
              ))}
              <DialogContentText>
                <p>
                  This action will disable the service that customer has still
                  until the end of the billing period.
                </p>

                <ul>
                  <li>
                    Cancellation - customer will be left with free service.
                  </li>
                  <li>
                    Downgrade - customer will be left with the service they're
                    downgrading to.
                  </li>
                </ul>
              </DialogContentText>
              <Box>
                <FormControl fullWidth>
                  {/* mandatory field */}
                  <TextField
                    label="Annotation"
                    {...form.register('annotation', {
                      required: 'Annotation is required',
                    })}
                    error={!!errors.annotation}
                    helperText={errors.annotation?.message}
                    required
                  />
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                Close
              </Button>
              <LoadingButton
                type="submit"
                size="large"
                disabled={isLoading}
                loading={isLoading}
                variant="contained"
              >
                Clear service
              </LoadingButton>
            </DialogActions>
          </form>
        </FormProvider>
      </HasPermission>
    </Dialog>
  );
}
