export const insightsMagpieBillingPeriodsMapping = {
  three_months: 'THREE_MONTHS',
  six_months: 'SIX_MONTHS',
  yearly: 'ANNUALLY',
  two_years: 'TWO_YEARS',
  three_years: 'THREE_YEARS',
};

// Insights stores billing periods as lowercase strings
const manualBillingPeriods = [
  insightsMagpieBillingPeriodsMapping.three_months,
  insightsMagpieBillingPeriodsMapping.six_months,
  insightsMagpieBillingPeriodsMapping.two_years,
  insightsMagpieBillingPeriodsMapping.three_years,
  'yearly', // Insights stores 'ANNUALLY' as 'yearly'
  insightsMagpieBillingPeriodsMapping.yearly, // let's keep "ANNUALLY" to be on the safe side
].map((period) => period.toLowerCase());

export function isManualBillingPeriod(billingPeriod: string | undefined) {
  if (!billingPeriod) {
    return false;
  }

  return manualBillingPeriods.includes(billingPeriod.toLowerCase());
}

export const defaultManualBillingPeriod =
  insightsMagpieBillingPeriodsMapping.yearly;
