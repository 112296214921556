import { MenuItem } from '@mui/material';
import { useState } from 'react';
import { ClearServiceModal } from '../../../domains/services/features/clear-service-modal/ClearServiceModal';
import { getScheduledProductChanges } from '../../../domains/sites/core/get-scheduled-product-changes';
import { Site } from '../../../domains/sites/models/site.model';
import { AlpProducts } from '../../../domains/products/models/product.model';

type MenuItemClearFairDowngradeAlpProps = {
  isAlp: boolean;
  accountId: number;
  sites: Record<number, Site<AlpProducts>> | undefined;
};

/**
  We would like to enable this when the account has one type of service but is scheduled to
  have this service changed to another one later.

  - Customer has cancelled totally, has still service they paid for, but will be free later
  - Customer has downgrade one of their products. Current service will move to a lower tier later.

  Currently, there's no straightforward way to check it, as service management exposes only raw data about services, so we use Site object to detect changes in products.

  Another option would be to use the  raw data from {@link: https://service-management.ew1-live-1.hotjarians.net/apidoc/redoc/#tag/ServicePeriods/operation/get__api_service-periods_%7Bentity_type%7D_%7Bentity_id%7D|service periods api}.
 */
export function MenuItemClearFairDowngradeAlp({
  isAlp,
  accountId,
  sites,
}: MenuItemClearFairDowngradeAlpProps) {
  const [open, setOpen] = useState(false);

  if (!isAlp || !sites) {
    return null;
  }

  const changes = getScheduledProductChanges(Object.values(sites)[0]);

  const noChanges = changes.length === 0;

  return (
    <>
      <MenuItem
        onClick={() => {
          setOpen((state) => !state);
        }}
        disabled={noChanges}
      >
        Clear (fair) downgrade service
      </MenuItem>
      <ClearServiceModal
        handleClose={() => setOpen(false)}
        isOpen={open}
        accountId={accountId}
        changes={changes}
      />
    </>
  );
}
