import moment from 'moment';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { HashmapRenderer } from '../../../../components/HashmapRenderer';
import {
  toCapitalizeRenderer,
  toSmallCapitalized,
  dateRenderer,
  planRenderer,
} from './account-service-table-renderers';

export const siteTableOptions: MUIDataTableOptions = {
  search: false,
  sort: true,
  filter: false,
  selectableRows: 'none',
  pagination: true,
  print: false,
  download: false,
  viewColumns: true,
  responsive: 'standard',
  elevation: 0,
};

function dateSort(order: 'asc' | 'desc') {
  return (a: { data: string }, b: { data: string }) => {
    return order === 'asc'
      ? moment(a.data).diff(moment(b.data))
      : moment(b.data).diff(moment(a.data));
  };
}

export const tableColumns: MUIDataTableColumnDef[] = [
  {
    name: 'Product name',
    options: { customBodyRender: toCapitalizeRenderer },
  },
  {
    name: 'Plan',
    options: {
      customBodyRender: planRenderer,
    },
  },
  {
    name: 'Allowance',
    options: {
      customBodyRender: HashmapRenderer,
    },
  },
  {
    name: 'Source',
    options: { customBodyRender: toSmallCapitalized },
  },
  {
    name: 'Start date UTC',
    options: { customBodyRender: dateRenderer, sortCompare: dateSort },
  },
  {
    name: 'End date UTC',
    options: { customBodyRender: dateRenderer, sortCompare: dateSort },
  },
  {
    name: 'Created date UTC',
    options: {
      customBodyRender: dateRenderer,
      sortCompare: dateSort,
    },
  },
  {
    name: 'Disabled',
  },
];
