import { buildUrl } from '../../../utils/api';

export const fetchSiteServicePeriodsURL = (siteId: string) =>
  buildUrl(`/api/v2/adm/service-management/service-periods/site/${siteId}`);

export function fetchAccountServicePeriodsURL(
  accountId: number,
  includeFuture: boolean
) {
  return `/api/v2/adm/service-management/service-periods/account/${accountId}?include_future=${String(
    includeFuture
  )}`;
}

export function fetchSiteEntitlementsURL(accountId: number, siteId: number) {
  return `/api/v2/adm/service-management/entities/${accountId}/site/${siteId}/entitlements`;
}

export function fetchAccountMeteringURL(accountId: number) {
  return `/api/v2/adm/service-management/metering/account/${accountId}`;
}

export const queryKeys = {
  accountServicePeriods: (accountId: number | undefined) => [
    'accountServicePeriods',
    accountId,
  ],
  siteEntitlements: (
    accountId: number | undefined,
    siteId: number | undefined
  ) => ['siteEntitlements', accountId, siteId],
  accountMetering: (accountId: number | undefined) => [
    'accountMetering',
    accountId,
  ],
};
