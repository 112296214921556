export enum ProductName {
  Observe = 'observe',
  Ask = 'ask',
}

export enum FeatureGroup {
  Basic = 'BASIC',
  Business = 'BUSINESS',
}

export enum AlpFeatureGroup {
  FREE = 'FREE',
  GROWTH = 'GROWTH',
  PRO = 'PRO',
}

interface Product<TFeatureGroup = FeatureGroup> {
  name: ProductName;
  effective_feature_group: TFeatureGroup;
  feature_group: TFeatureGroup;
  price: string;
}

export interface ObserveProduct<TFeatureGroup = FeatureGroup>
  extends Product<TFeatureGroup> {
  name: ProductName.Observe;
  session_average_seven_day: number;
  effective_cc_sessions: number;
  effective_sessions: number;
  cc_sessions: number;
  sessions: number;
}

export interface AskProduct<TFeatureGroup = FeatureGroup>
  extends Product<TFeatureGroup> {
  name: ProductName.Ask;
  response_count: number;
  monthly_responses: number;
  effective_monthly_responses: number;
}

export type AlpAskProduct = AskProduct<AlpFeatureGroup>;
export type AlpObserveProduct = ObserveProduct<AlpFeatureGroup>;

export type Products<TFeatureGroup = FeatureGroup> = [
  ObserveProduct<TFeatureGroup>,
  AskProduct<TFeatureGroup>
];

export type AlpProducts = Products<AlpFeatureGroup>;
