import ColoredLabel from '../../../components/ColoredLabel';
import { AlpPlan } from '../models/service-alp.model';

const planColors = {
  free: 'var(--green)',
  growth: 'var(--amber)',
  pro: 'var(--red)',
};

type PlanBadgeProps = {
  plan: AlpPlan;
};

export function PlanBadge({ plan }: PlanBadgeProps) {
  return <ColoredLabel color={planColors[plan]}>{plan}</ColoredLabel>;
}
