import type { ProductPlanAllowance } from '../../../sites/core/get-scheduled-product-changes';
import { AlpPlan, AlpProduct } from '../../models/service-alp.model';
import { PlanBadge } from '../../ui/plan-badge';
import {
  AlpFeatureGroup,
  ProductName,
} from '../../../products/models/product.model';
import styles from './product-plan-allowance.module.css';

type ProductPlanAllowanceBadgeProps = {
  allowance: ProductPlanAllowance;
};

const featureGroupToPlanMap = new Map<AlpFeatureGroup, AlpPlan>([
  [AlpFeatureGroup.FREE, 'free'],
  [AlpFeatureGroup.GROWTH, 'growth'],
  [AlpFeatureGroup.PRO, 'pro'],
]);

const productNameToPlanMap = new Map<ProductName, AlpProduct>([
  [ProductName.Observe, 'dxa'],
  [ProductName.Ask, 'voc'],
]);

export function ProductPlanAllowanceBadge({
  allowance,
}: ProductPlanAllowanceBadgeProps) {
  const productName = productNameToPlanMap.get(allowance.productName);
  const plan = featureGroupToPlanMap.get(allowance.featureGroup);

  if (!productName || !plan) {
    return null;
  }
  return (
    <span className={styles.container}>
      {productName} <PlanBadge plan={plan} /> {allowance.value}
    </span>
  );
}
