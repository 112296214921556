import isEqual from 'lodash/isEqual';
import type {
  AlpAskProduct,
  AlpObserveProduct,
  ProductName,
  AlpFeatureGroup,
  AlpProducts,
} from '../../products/models/product.model';
import type { Site } from '../models/site.model';

export type ProductPlanAllowance = {
  productName: ProductName;
  featureGroup: AlpFeatureGroup;
  value: number;
};

export type ProductPlanAllowanceChange = {
  now: ProductPlanAllowance;
  later: ProductPlanAllowance;
};

function getProductChange(
  product: AlpObserveProduct | AlpAskProduct
): ProductPlanAllowanceChange {
  const { name, effective_feature_group, feature_group } = product;

  if (name === 'ask') {
    const { effective_monthly_responses, monthly_responses } = product;
    return {
      now: {
        productName: name,
        featureGroup: effective_feature_group,
        value: effective_monthly_responses,
      },
      later: {
        productName: name,
        featureGroup: feature_group,
        value: monthly_responses,
      },
    };
  }

  const { effective_cc_sessions, cc_sessions } = product;
  return {
    now: {
      productName: name,
      featureGroup: effective_feature_group,
      value: effective_cc_sessions,
    },
    later: {
      productName: name,
      featureGroup: feature_group,
      value: cc_sessions,
    },
  };
}

function hasProductChanged(productChange: ProductPlanAllowanceChange): boolean {
  return !isEqual(productChange.now, productChange.later);
}

/**
 * We use here Site object to detect changes in products.
 * This is not the best design, but it's the best we can do with the current API.
 */
export function getScheduledProductChanges(
  site: Site<AlpProducts> | undefined
) {
  if (!site) {
    return [];
  }

  const productChanges = site.products
    .map((product) => getProductChange(product))
    .filter((change) => hasProductChanged(change));

  return productChanges;
}
