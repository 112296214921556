import { useState, ChangeEvent, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import {
  initiateSiteBundleTrial,
  initiateSiteSingleTrial,
} from '../../../domains/trials/api/initiateSiteTrial';
import { validateTrialEligibility } from '../../../domains/trials/api/validateTrialEligibility';
import { useTrialV2Eligibility } from '../../../domains/trials/api/useTrialV2Eligibility';

import styles from './TrialActivationModal.module.css';
import { SKU } from '../../../typings';
import { AdmModalProps } from '../AdmModalRoot';

import { useTrialV2CustomDuration } from '../../../domains/trials/api/useTrialV2CustomPeriod';
import { convertReadableNumber } from '../../../domains/trials/utils/common';

type TrialBundles = {
  id: string;
  bundleName: `${SKU}_${SKU}`;
  label: string;
};

type Props = {
  modalProps: Record<string, string> & {
    productsCatalog: {
      sku: SKU;
      planName: string;
      productName: string;
      allowance: {
        monthlyResponses?: number;
        dailyCcSessions?: number;
        quotas?: {
          analyticsSessionQuota: number;
          monthlyResponses: number;
          recordingSessionCount: number;
        };
      };
    }[];
  } & { parsedBundleTrials: TrialBundles[] };
  onSuccess: () => void;
};

const TrialActivationModal = ({
  props: { modalProps, onSuccess },
  onCloseModal,
}: AdmModalProps<Props>) => {
  const [selectedTrialId, setSelectedTrialId] = useState<string | undefined>(
    undefined
  );
  const [customTrialSelected, setCustomTrialSelected] = useState(false);
  const [isTrialEligible, setIsTrialEligible] = useState(true);
  const { eligibleTrials } = useTrialV2Eligibility({
    site_id: Number(modalProps.siteId),
    account_id: Number(modalProps.accountId),
  });
  const { trialPeriod, trialPeriodElement } = useTrialV2CustomDuration({
    mode: 'create',
  });

  useEffect(() => {
    if (selectedTrialId === undefined && eligibleTrials.length > 0) {
      setSelectedTrialId(eligibleTrials[0]);
    }
  }, [eligibleTrials]);

  const handleCustomSelectToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomTrialSelected(event.target.checked);
    setSelectedTrialId(undefined);
  };
  const filterAskProducts = modalProps?.productsCatalog?.filter(
    (product) => product.productName === 'Ask'
  );
  const filterVoCProducts = modalProps?.productsCatalog?.filter(
    (product) => product.productName === 'VoC' && product.planName === 'Growth'
  );
  const filterObserveProducts = modalProps?.productsCatalog?.filter(
    (product) => product.productName === 'Observe'
  );
  const filterDXAProducts = modalProps?.productsCatalog?.filter(
    (product) => product.productName === 'DXA' && product.planName === 'Growth'
  );

  const parsedAskProducts = filterAskProducts.map((product) => ({
    id: product.sku,
    duration: trialPeriod,
    bundleName: product.sku,
    label: `${product.productName} ${product.planName} ${product.allowance.monthlyResponses} | ${product.sku}`,
  }));
  const parsedVoCProducts = filterVoCProducts.map((product) => ({
    id: product.sku,
    duration: trialPeriod,
    bundleName: product.sku,
    label: `${product.productName} ${product.planName} ${convertReadableNumber(
      product.allowance.quotas?.monthlyResponses as number
    )} responses`,
  }));
  const parsedObserveProducts = filterObserveProducts.map((product) => ({
    id: product.sku,
    duration: trialPeriod,
    bundleName: product.sku,
    label: `${product.productName} ${product.planName} ${product.allowance.dailyCcSessions} | ${product.sku}`,
  }));
  const parsedDXAProducts = filterDXAProducts.map((product) => ({
    id: product.sku,
    duration: trialPeriod,
    bundleName: product.sku,
    label: `${product.productName} ${product.planName} ${convertReadableNumber(
      product.allowance.quotas?.analyticsSessionQuota as number
    )} sessions (${convertReadableNumber(
      product.allowance.quotas?.recordingSessionCount as number
    )} replays)`,
  }));

  const parseBundleTrials = () => {
    const parsedEligibleTrials: TrialBundles[] = [];

    eligibleTrials?.forEach((trial) => {
      const splitTrials = trial.split('_');

      const parsedTrial1 =
        modalProps.productsCatalog.filter(
          (product) => product.sku === splitTrials[0]
        ) ?? [];
      const parsedTrial2 =
        modalProps.productsCatalog.filter(
          (product) => product.sku === splitTrials[1]
        ) ?? [];

      parsedEligibleTrials.push({
        id: trial,
        bundleName: trial,
        label: `${parsedTrial1[0]?.productName} ${parsedTrial1[0]?.planName} ${
          modalProps.isAlp
            ? `${convertReadableNumber(
                parsedTrial1[0]?.allowance.quotas
                  ?.analyticsSessionQuota as number
              )} sessions (${convertReadableNumber(
                parsedTrial1[0]?.allowance.quotas
                  ?.recordingSessionCount as number
              )} replays)`
            : parsedTrial1[0]?.allowance.monthlyResponses
        } & ${parsedTrial2[0]?.productName} ${parsedTrial2[0]?.planName} ${
          modalProps.isAlp
            ? `${convertReadableNumber(
                parsedTrial2[0]?.allowance.quotas?.monthlyResponses as number
              )} responses`
            : parsedTrial2[0]?.allowance.dailyCcSessions
        } ${modalProps.isAlp ? '' : `| ${trial}`}`,
      });
    });

    return parsedEligibleTrials;
  };

  const handleEnableTrials = async () => {
    // TODO: This will probably be adapted to pick the SKU's/duration
    // etc. from other ways rather than statically assign them.

    if (modalProps === undefined || onSuccess === undefined) {
      return null;
    }

    const source = `adm:${
      modalProps?.email ? modalProps?.email : modalProps?.organizationId
    }`;

    const trial = [
      ...parseBundleTrials(),
      ...(modalProps.isAlp
        ? [...parsedVoCProducts, ...parsedDXAProducts]
        : [...parsedAskProducts, ...parsedObserveProducts]),
    ].find((bundle) => bundle.id === selectedTrialId);

    if (!trial) {
      throw new Error(
        `Unable to initialize trial bundle. Selected trial is not supported`
      );
    }
    const target = modalProps.isAlp
      ? `account_id: ${modalProps?.accountId}`
      : `site_id: ${modalProps?.siteId}`;
    const errorMessage = `Unable to initialize trial bundle ${trial.label} for ${target}`;

    if (trial.bundleName.includes('_')) {
      // Inititate trial for Observe Scale 40,000
      await initiateSiteBundleTrial(
        {
          entity: {
            type: modalProps.isAlp ? 'account' : 'site',
            id: modalProps.isAlp ? modalProps?.accountId : modalProps?.siteId,
          },
          hotjar_account_id: modalProps?.accountId,
          bundle_name: trial.bundleName as `${SKU}_${SKU}`,
          duration: trialPeriod,
          source,
        },
        errorMessage
      );
    } else {
      await initiateSiteSingleTrial(
        {
          entity: {
            type: modalProps.isAlp ? 'account' : 'site',
            id: modalProps.isAlp ? modalProps?.accountId : modalProps?.siteId,
          },
          hotjar_account_id: modalProps?.accountId,
          sku: trial.bundleName as SKU,
          duration: trialPeriod,
          source,
        },
        errorMessage
      );
    }

    onSuccess();
  };

  const onBundleChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    setSelectedTrialId(event.target.value);
    setCustomTrialSelected(false);
  };

  const onCustomTrialChange = async (event: SelectChangeEvent) => {
    setSelectedTrialId(event.target.value);
    setIsTrialEligible(true);

    const source = `adm:${
      modalProps?.email ? modalProps?.email : modalProps?.organizationId
    }`;

    const res = await validateTrialEligibility(
      {
        entity: {
          type: modalProps.isAlp ? 'account' : 'site',
          id: modalProps.isAlp ? modalProps?.accountId : modalProps?.siteId,
        },
        hotjar_account_id: modalProps?.accountId,
        sku: event.target.value as SKU,
        source,
      },
      ''
    );

    if (res.eligible.length === 0) {
      setIsTrialEligible(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onCloseModal}>
      <DialogTitle>Initiate Trial</DialogTitle>
      <DialogContent>
        <p>
          {`You're about to start a trial for ${
            modalProps.isAlp ? 'account' : 'site'
          } ${
            modalProps?.name
              ? modalProps.name
              : `ID: ${
                  modalProps.isAlp ? modalProps?.accountId : modalProps?.siteId
                }`
          }`}
        </p>
        <p className={styles.bold}>Choose trial type:</p>
        <div className={styles.trialOptions}>
          {parseBundleTrials().map((bundle) => {
            return (
              <div key={bundle.id}>
                <input
                  onChange={onBundleChange}
                  type="radio"
                  id={bundle.id}
                  name={bundle.label}
                  value={bundle.id}
                  checked={selectedTrialId === bundle.id}
                />
                <label htmlFor={bundle.id}>{bundle.label}</label>
              </div>
            );
          })}
          <div>
            <input
              onChange={handleCustomSelectToggle}
              type="radio"
              id="custom"
              name="Custom"
              value="custom"
              checked={customTrialSelected}
            />
            <label htmlFor="custom">Custom</label>
          </div>
        </div>
        {customTrialSelected ? (
          <>
            <p>Choose single trial:</p>
            <FormControl variant="filled">
              <Box sx={{ minWidth: 120, position: 'relative', m: 1 }}>
                <InputLabel id="select-single-product-trial-label">
                  Select an option
                </InputLabel>
                <Select
                  labelId="select-single-product-trial-label"
                  id="select-single-product-trial"
                  value={selectedTrialId}
                  onChange={onCustomTrialChange}
                  defaultValue=""
                  native
                  sx={{ width: '300px' }}
                >
                  <option aria-label="None" value={undefined} />
                  <optgroup label={modalProps.isAlp ? 'VoC' : 'Ask'}>
                    {(modalProps.isAlp
                      ? parsedVoCProducts
                      : parsedAskProducts
                    ).map((product) => (
                      <option key={product.id} value={product.bundleName}>
                        {product.label}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup label={modalProps.isAlp ? 'DXA' : 'Observe'}>
                    {(modalProps.isAlp
                      ? parsedDXAProducts
                      : parsedObserveProducts
                    ).map((product) => (
                      <option key={product.id} value={product.bundleName}>
                        {product.label}
                      </option>
                    ))}
                  </optgroup>
                </Select>
                {!isTrialEligible ? (
                  <FormHelperText sx={{ color: 'red' }}>
                    {`This trial is not eligible for this ${
                      modalProps.isAlp ? 'account' : 'site'
                    }`}
                  </FormHelperText>
                ) : null}
              </Box>
            </FormControl>
            <p className={styles.textGray}>
              {`Only one single-product trial can be active for a ${
                modalProps.isAlp ? 'account' : 'site'
              }. To give
              trial to a second product, choose a bundle trial.`}
            </p>
          </>
        ) : null}

        {/* Trial V2 duration selectors*/}
        {trialPeriodElement}
        <p className={styles.textGray}>
          Please remember that this site's tracking code must be configured
          before continuing
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Cancel</Button>
        <Button
          onClick={handleEnableTrials}
          disabled={selectedTrialId === undefined || !isTrialEligible}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrialActivationModal;
