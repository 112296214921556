import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from '@mui/material';
import { TrimmedText } from '../../../components/TrimmedText';
import { convertReadableNumber } from '../../products/utils/convert-to-readable-number';

export function AllowancesUsageTableRenderer(value: {
  [key: string]: {
    quota: number;
    usage: number;
  };
}) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Allowance</TableCell>
          <TableCell>Quota</TableCell>
          <TableCell>Usage</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(value).map(([key, { quota, usage }]) => {
          return (
            <TableRow key={key}>
              <TableCell>
                <TrimmedText text={key} maxLength={20} />
              </TableCell>
              <TableCell>{convertReadableNumber(quota)}</TableCell>
              <TableCell>{convertReadableNumber(usage)}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
