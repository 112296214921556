import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export type ServiceProvisionPayload = {
  annotation: string;
};

async function clearService(data: ServiceProvisionPayload, accountId: number) {
  const payload = {
    action: 'clear_account_sites_interims',
    adm_action_annotation: data.annotation,
  };

  try {
    const response = await fetch(`/api/v2/adm/account/${accountId}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to clear service');
    }

    return response.json();
  } catch (error) {
    throw new Error('Failed to clear service');
  }
}

export function useClearServiceMutation(
  accountId: number,
  options: UseMutationOptions<Response, Error, ServiceProvisionPayload>
) {
  return useMutation({
    ...options,
    mutationFn: (data: ServiceProvisionPayload) => {
      return clearService(data, accountId);
    },
  });
}
