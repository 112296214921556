import React, { useContext, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Alert,
} from '@mui/material';
import { getProductCatalogFromMagpie } from '../../../utils/api';
import { getProductCatalogVersion } from '../../../domains/accounts/utils/getProductCatalogVersion';
import {
  previewPriceForMigrate,
  migrateManualCustomer,
} from '../../../utils/api-migrate';
import { LookupContext } from '../../../contexts/LookupContext';
import { doesCountryRequireState } from '../../../utils/countries';
import { useToasts } from 'react-toast-notifications';
import { BillingAddress } from '../SubscriptionCreationModal/BillingAddress';
import { Cost } from '../SubscriptionCreationModal/Cost';
import {
  SubscriptionsCommonFields,
  PaymentTerms,
} from '../SubscriptionCreationModal/SubscriptionsCommonFields';
import { Notes } from '../SubscriptionCreationModal/Notes';
import { LoadingDialog } from '../LoadingDialog/index';
import { SitesToMigrate } from './SitesToMigrate';
import { getSitesToMigrate } from './products-to-skus';
import { UserFields } from './UserFields';
import { SkipTaxationField } from './SkipTaxation';
import { StartDate } from './StartDate';
import { insightsMagpieBillingPeriodsMapping } from '../../../domains/magpie/models';

export const MigrateManualCustomerModal = ({
  props: modalProps,
  onCloseModal,
}) => {
  const [productsCatalog, setPlans] = useState(undefined);
  const [formData, setFormData] = useState({
    addressLine1: '',
    addressLine2: '',
    country: '',
    city: '',
    state: '',
    county: '',
    zipCode: '',
    companyName: '',
    taxNumber: '',
    purchaseOrderNumber: '',
    invoiceNotes: '',
    billingPeriod: '',
    subTotal: 0.0,
    discountPercentage: 0.0,
    taxPercentage: 0.0,
    total: 0.0,
    paymentTerm: PaymentTerms[0].value,
    renewAmountWithoutTax: undefined,
    renewAmountWithTax: undefined,
    emailAddress: '',
    firstName: '',
    lastName: '',
    skipTaxation: false,
    startDate: undefined,
  });

  const { addToast } = useToasts();

  const context = useContext(LookupContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // "DEFAULT" | "LOADING" | "ERROR"
  const [previewState, setPreviewState] = useState('DEFAULT');

  const previewCostsAbortRequestController = useRef();

  const { sites } = context;
  const { account, user } = context;
  const { currency, id: accountId } = account;
  const sitesToMigrate = getSitesToMigrate(sites, productsCatalog);

  // update state once if account is available
  useEffect(() => {
    const startDate = moment(account.next_billing_date)
      .subtract(1, 'year')
      .format('YYYY-MM-DD');

    setFormData((data) => ({
      ...data,
      emailAddress: user?.email ?? '',
      firstName: user?.name ?? '',
      lastName: user?.name ?? '',
      companyName: account.company,
      taxNumber: account.vat_number,
      billingPeriod: insightsMagpieBillingPeriodsMapping[account.billing_cycle],
      startDate,
    }));
  }, [account, user]);

  const isDataReadyForSubmission = () => {
    const { companyName, country } = formData;
    // Check all required fields
    if (
      sitesToMigrate.length === 0 ||
      !companyName ||
      !country ||
      (doesCountryRequireState(country) && !formData.state) ||
      formData.discountPercentage === '' ||
      formData.emailAddress === ''
    ) {
      return false;
    }
    return true;
  };

  const getPayloadData = () => {
    // Remove unneeded data
    const { subTotal, taxPercentage, total, ...rest } = formData;
    return {
      ...rest,
      sitesToMigrate,
    };
  };

  useEffect(() => {
    if (!accountId || !formData.billingPeriod || !currency) return;

    const getPlans = async () => {
      try {
        const plansRes = await getProductCatalogFromMagpie(
          formData.billingPeriod.toLowerCase(),
          currency,
          getProductCatalogVersion(account)
        );
        const plansData = await plansRes.json();

        const productsCatalog = plansData
          .map((product) =>
            product.plans.map((plan) =>
              plan.allowances.map((allowance) => {
                return {
                  sku: allowance.sku,
                  allowance,
                  planName: plan.plan_name,
                  productName: product.product_name,
                };
              })
            )
          )
          .flat(2);
        setPlans(productsCatalog);
      } catch (e) {
        console.log(e);
      }
    };
    getPlans();
  }, [formData.billingPeriod, currency, accountId, account]);

  useEffect(() => {
    if (!isDataReadyForSubmission()) {
      return;
    }

    // Cancel the old request. We don't want to have parallel preview requests.
    previewCostsAbortRequestController.current?.abort();
    setPreviewState('LOADING');

    const abortController = new AbortController();
    previewCostsAbortRequestController.current = abortController;
    const previewCosts = async () => {
      try {
        const rawResponse = await previewPriceForMigrate(
          accountId,
          getPayloadData(),
          productsCatalog,
          abortController.signal
        );

        setPreviewState('DEFAULT');

        const response = await rawResponse.json();
        if (!rawResponse.ok) {
          const errorMessage = response?.message ?? rawResponse.status;
          addToast(`Preview error: ${errorMessage}`, {
            appearance: 'error',
            autoDismiss: true,
          });
          console.error('Order', errorMessage);
        }

        const { amount_without_tax: subTotal, amount_with_tax: total } =
          response.pay_now;

        const {
          tax_rate: taxPercentage,
          amount_with_tax: renewAmountWithTax,
          amount_without_tax: renewAmountWithoutTax,
        } = response.renew_with;

        setFormData({
          ...formData,
          subTotal,
          taxPercentage,
          total,
          renewAmountWithTax,
          renewAmountWithoutTax,
        });
      } catch (error) {
        console.log(error);
        if (error?.name !== 'AbortError') {
          setPreviewState('ERROR');
        }
      }
    };

    previewCosts();

    // We don't need to add the while formData object as a dependency. It will keep calling this as we are setting the formData at the end
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modalProps,
    context.user,
    formData.state,
    formData.country,
    formData.taxNumber,
    formData.companyName,
    formData.billingPeriod,
    formData.discountPercentage,
    formData.emailAddress,
    formData.skipTaxation,
    formData.startDate,
  ]);

  if (!modalProps) {
    return null;
  }

  const handleUpdateField = (event) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await migrateManualCustomer(
        accountId,
        {
          ...getPayloadData(),
          additionalEmailAddresses: context.account.email_recipients,
        },
        productsCatalog
      );
      if (!response.ok) {
        const parsedResponse = await response.json();
        throw new Error(parsedResponse?.message ?? response.status);
      }
      window.location.reload();
    } catch (error) {
      setIsSubmitting(false);
      addToast('Order error', { appearance: 'error', autoDismiss: true });
      console.error('Order', error);
    }
  };

  if (isSubmitting) {
    return (
      <LoadingDialog title="Migrating Customer">
        <div>
          It might take a while. The page will refresh automatically after the
          request is processed. If anything goes wrong, please leave us a
          message at <strong>#payments-helpdesk</strong>.
        </div>
      </LoadingDialog>
    );
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open
      onClose={onCloseModal}
      disableEnforceFocus
    >
      <DialogTitle>Migrate account {accountId} to Billing 3.0</DialogTitle>
      <DialogContent>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            <BillingAddress
              formData={formData}
              handleUpdateField={handleUpdateField}
            />
          </Grid>
          <Grid item xs={6}>
            <Cost
              subTotal={formData.subTotal}
              taxPercentage={formData.taxPercentage}
              total={formData.total}
              renewAmountWithTax={formData.renewAmountWithTax}
              renewAmountWithoutTax={formData.renewAmountWithoutTax}
              handleUpdateField={handleUpdateField}
              isPreviewLoading={previewState === 'LOADING'}
              currency={currency}
            />
            {previewState === 'ERROR' && (
              <Alert severity="error">There was an error loading preview</Alert>
            )}
            <Notes
              invoiceNotes={formData.invoiceNotes}
              handleUpdateField={handleUpdateField}
              textFieldRows={3}
            />
            <SkipTaxationField
              handleUpdateField={(event) => {
                setFormData((data) => ({
                  ...data,
                  skipTaxation: event.target.checked,
                }));
              }}
              skipTaxation={formData.skipTaxation}
            />
          </Grid>
        </Grid>

        <div>
          <UserFields
            formData={formData}
            handleUpdateField={handleUpdateField}
          />
        </div>

        <h2>Subscriptions</h2>
        <SubscriptionsCommonFields
          formData={formData}
          handleUpdateField={handleUpdateField}
          paymentTerms={PaymentTerms}
          isCreationMode
          allowBillingPeriodSelection
        />
        <div>
          <StartDate
            handleUpdateField={(momentDate) => {
              setFormData((data) => ({
                ...data,
                startDate: momentDate.format('YYYY-MM-DD'),
              }));
            }}
            startDate={formData.startDate}
          />
        </div>

        {sitesToMigrate.length > 0 ? (
          <SitesToMigrate
            sitesToMigrate={sitesToMigrate}
            productsCatalog={productsCatalog}
          />
        ) : (
          <Alert severity="warning">
            There are no sites with paid effective plans.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={
            previewState === 'LOADING' ||
            previewState === 'ERROR' ||
            !isDataReadyForSubmission()
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
