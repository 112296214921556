import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import type { AccountMetering } from '../models/service-alp.model';
import { queryKeys, fetchAccountMeteringURL } from './urls';

export function useAccountMetering({
  accountId,
  enabled,
  onError,
}: {
  accountId: number | undefined;
  enabled: boolean;
  onError: (message: string) => void;
}) {
  const { data, isLoading } = useQuery({
    queryKey: queryKeys.accountMetering(accountId),
    retry: false,
    queryFn: async () => {
      if (!accountId) {
        return undefined;
      }

      try {
        const response = await fetch(fetchAccountMeteringURL(accountId));

        if (!response.ok) {
          onError('Failed to get account metering');
          throw new Error('Failed to get account metering');
        }

        const returnData: AccountMetering = await response.json();
        return returnData;
      } catch (error: unknown) {
        if (error instanceof Error) {
          onError(`Error getting account metering: ${error.message}.`);
          throw new Error(`Error getting account metering: ${error.message}.`);
        }
      }
    },
    enabled: !isNil(accountId) && enabled,
  });

  return {
    accountMeteringData: data,
    isLoading,
  };
}
